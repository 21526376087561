<template>
  <div class="box">
    <div class="tu">
      <div class="whh">
        <div class="header_img_11">
          <img
            class="header_imgs"
            src="../../assets/peixunkecheng.png"
            alt=""
          />
        </div>
        <div class="tablist">
          <div class="title">分类</div>
          <ul>
            <li>
              <div class="item1">
                企业培训<span @click="go(2, 'no')">[ 更多 ]</span>
              </div>
              <div class="itemlist">
                <div
                  v-for="(item, index) in list2.classify"
                  @click="go(2, 'yes', item)"
                  :key="index"
                >
                  <div v-if="index < 3">{{ item.title }}</div>
                </div>
              </div>
            </li>
            <li>
              <div class="item1">
                技能补贴<span @click="go(1, 'no')">[ 更多 ]</span>
              </div>
              <div class="itemlist">
                <div
                  v-for="(item, index) in list.classify"
                  @click="go(1, 'yes', item)"
                  :key="index"
                >
                  <div v-if="index < 3">{{ item.title }}</div>
                </div>
              </div>
            </li>
            <li>
              <div class="item1">
                个人兴趣<span @click="go(3, 'no')">[ 更多 ]</span>
              </div>
              <div class="itemlist">
                <div
                  v-for="(item, index) in list3.classify"
                  @click="go(3, 'yes', item)"
                  :key="index"
                >
                  <div v-if="index < 3">{{ item.title }}</div>
                </div>
              </div>
            </li>
          </ul>
          <div class="bottom" @click="go(4, 'no')">更多行业分类 ></div>
        </div>
      </div>
    </div>
    <div style="height: 470px"></div>
    <div class="bj">
      <div class="w">
        <div class="commoditymodule" v-if="list2.class.length > 0">
          <div class="title">企业培训</div>
          <div class="introduce">
            我们聚合职业培训行业里知名的大咖资源 打造企业和机构专属内容体系
            为企业和机构提供智能的内容赋能
          </div>
          <div class="productlist">
            <div class="item" v-for="(item, index) in list2.class" :key="index">
              <div @click="go2(item)" v-if="index < 10">
                <img :src="item.cover" alt="" />
                <div class="title1">
                  {{ item.title }}
                </div>
                <div class="hour">共{{ item.class_hour }}节</div>
                <div class="price">
                  <span>￥{{ item.price }}</span>
                  <!-- <span v-if="item.payment == 0 || item.payment == null">
                    <span v-if="school">
                      <span v-if="iscost == 1">免费</span>
                    </span>
                    <span v-else>
                      {{ item.cost == 0 ? "免费" : "￥" + item.cost }}
                    </span>
                  </span> -->
                  <!-- <span v-if="payment==2">￥{{item.cost}}<span>元</span></span>  -->
                  <!-- <span v-if="item.payment - 0 >= 1">
                    <span
                      v-if="
                        className == '岗位培训' ||
                        (item.name && item.name.indexOf('岗位培训') > -1)
                      "
                    >
                      <span v-if="school">
                        <span v-if="iscost == 1"
                          >￥{{ item.cost }} 元/人/课时</span
                        >
                      </span>
                      <span v-else> ￥{{ item.cost }} 元/人/课时 </span>
                    </span>
                    <span v-else>
                      <span v-if="school">
                        <span v-if="iscost == 1"
                          >￥{{ item.cost }}<span>元</span></span
                        >
                      </span>
                      <span v-else> ￥{{ item.cost }}<span>元</span> </span>
                    </span>
                  </span> -->
                  <span class="span">{{ item.total_people }}人最近报名</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="commoditymodule" v-if="list.class.length > 0">
          <div class="title">技能补贴</div>
          <div class="introduce">
            提供国家标准数字化资源课程 技能培训的同时可以降低培训成本
          </div>
          <div class="productlist">
            <div class="item" v-for="(item, index) in list.class" :key="index">
              <div @click="go1(item)" v-if="index < 10">
                <img :src="item.cover" alt="" />
                <div class="title1">
                  {{ item.title }}
                </div>
                <div class="hour">共{{ item.class_hour }}节</div>
                <div class="price">
                  <span>￥{{ item.price }}</span>

                  <span class="span">{{ item.total_people }}人最近报名</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="commoditymodule" v-if="list3.class.length > 0">
          <div class="title">个人兴趣</div>
          <div class="introduce">
            发掘个人所喜爱擅长的职业技能，成为一技之长
          </div>
          <div class="productlist">
            <div v-for="(item, index) in list3.class" :key="index" class="item">
              <div @click="go1(item)" v-if="index < 10">
                <img :src="item.cover" alt="" />
                <div class="title1">
                  {{ item.title }}
                </div>
                <div class="hour">共{{ item.class_hour }}节</div>
                <div class="price">
                  <span>￥{{ item.price }}</span>
                  <span class="span">{{ item.total_people }}人最近报名</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      list: [],
      list2: [],
      list3: [],
    };
  },
  methods: {
    ...mapActions(["getIndex", "getphoneIndex_x"]),
    // 一级分类
    go(type, flag, item) {
      console.log(flag);
      console.log(type);
      console.log(item);
      if (flag == "no") {
        this.$router.push({
          path: "/allcourses",
          query: {
            type: type,
            is_type: 1,
          },
        });
      } else {
        this.$router.push({
          path: "/allcourses",
          query: {
            type: type,
            name: item.title,
            id: item.id,
            is_type: 2,
          },
        });
      }
    },
    // 二级分类

    go1(item) {
      this.$router.push({
        path: "/coursedetails",
        query: {
          type: "课程培训",
          id: item.id,
        },
      });
    },
    // 三级分类
    go2(item) {
      this.$router.push({
        path: "/enterprisecurriculumdetails",
        query: {
          type: "课程培训",
          id: item.id,
        },
      });
    },
  },
  // 列表数据
  mounted() {
    this.getphoneIndex_x({
      is_charge: 1,
    }).then((res) => {
      if (res.successCode == 200) {
        this.list = res.responseBody;
      }
    });
    this.getphoneIndex_x({
      is_charge: 2,
    }).then((res) => {
      if (res.successCode == 200) {
        this.list2 = res.responseBody;
      }
    });
    this.getphoneIndex_x({
      is_charge: 3,
    }).then((res) => {
      if (res.successCode == 200) {
        this.list3 = res.responseBody;
      }
    });

    // this.getIndex({
    //   type: 2,
    // }).then((res) => {
    //   if (res.code == 200) {
    //     this.list2 = res.data;
    //   }
    // });
    // this.getIndex({
    //   type: 3,
    // }).then((res) => {
    //   if (res.code == 200) {
    //     this.list3 = res.data;
    //   }
    // });
  },
};
</script>

<style lang="scss" scoped>
.box {
  overflow: hidden;
  position: relative;
}
.tu {
  position: absolute;
  left: 50%;
  height: 470px;
  width: 1920px;
  transform: translateX(-50%);
  background: url("../../assets/banner_background.png");
}
.header_img_11 {
  position: absolute;
  left: 50%;
  height: 470px;
  width: 1920px;
  transform: translateX(-50%);
  vertical-align: bottom;
}
.header_imgs {
  width: 1200px;
  display: block;
  margin: 0 auto;
  margin-top: 5px;
}

.tablist {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  width: 280px;
  height: 470px;
  background: #002f6d;
  opacity: 0.7966;
  .title {
    font-size: 21px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
  ul {
    li {
      margin-top: 15px;
      margin-bottom: 15px;
      .itemlist {
        margin-top: 6px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        div {
          cursor: pointer;
        }
      }
      .item1 {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        span {
          cursor: pointer;

          color: #6baaff;
        }
      }
    }
  }
  .bottom {
    padding-left: 130px;
    cursor: pointer;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    height: 36px;
    line-height: 36px;
    background: #002f6d;
  }
}
.bj {
  overflow: hidden;
  background: url("../../assets/beijingtu.png");
}
.commoditymodule {
  .title {
    margin: 59px 0 14px 0;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .introduce {
    text-align: center;
    margin-bottom: 48px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #87878c;
  }
  .productlist {
    display: flex;
    flex-wrap: wrap;
    .item {
      cursor: pointer;
      margin-right: 24px;
      margin-bottom: 22px;
      width: 220px;
      height: 216px;
      &:nth-child(5n) {
        margin-right: 0px;
      }
      img {
        width: 220px;
        height: 124px;
      }
      .title1 {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .hour {
        margin: 6px 0 7px 0;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #9d9d9d;
        line-height: 17px;
      }
      .price {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ef6430;
        line-height: 20px;
        .span {
          margin-left: 9px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #9d9d9d;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
